<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="editRoom.name"
          :label="$t('name')"
          dense
          outlined
        ></v-text-field>
        <v-autocomplete
          v-model="editRoom.floor"
          outlined
          dense
          :items="floorItems"
          :label="$t('floor')"
          item-text="name"
          item-value="id"
          return-object
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          :disabled="disableEditBtn"
          color="green"
          class="white--text"
          depressed
          @click="editRecord"
          :loading="editBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      editRoom: {
        name: "",
        floor: null,
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editRoom.name = this.recordData.name;
      this.editRoom.floor = this.recordData.floor;
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/room/${this.recordData.id}`, {
          name: this.editRoom.name,
          floor_id: this.editRoom.floor.id,
        });
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      floorItems: "floor/getFloors",
    }),
    disableEditBtn() {
      if (!this.editRoom.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.editRoom.name = this.recordData.name;
    this.editRoom.floor = this.recordData.floor;
  },
};
</script>

<style></style>
