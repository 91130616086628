<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('room:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t('add room') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t('add room') }}
                </v-card-title>
                <v-card-text>
                  <v-text-field :label="$t('name')" v-model="newRoom.name" outlined dense color="#757575"
                    type="text"></v-text-field>
                  <v-autocomplete v-model="newRoom.floor_id" outlined dense :items="floorItems" :label="$t('floor')"
                    item-text="name" item-value="id"></v-autocomplete>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitNewRoom" :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn">{{ $t('add') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="3">
            <v-text-field v-model="nameSearch" :label="$t('search by name')" dense outlined hide-details></v-text-field>
          </v-col>
          <v-col md="3">
            <v-autocomplete hide-details v-model="searchFloorId" outlined dense :items="floorItems"
              :label="$t('search by floor')" item-text="name" item-value="id" clearable></v-autocomplete>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)" serverRoute="/room" :enableDelete="displayDeleteIcon">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-room-modal v-if="returnAbility('room:update')" @recordUpdated="fetchData"
            :recordData="row"></app-edit-room-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import editRoomModal from "./editRoomModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    appEditRoomModal: editRoomModal,
  },
  data() {
    return {
      nameSearch: "",
      searchFloorId: null,
      dialog: false,
      submitBtnLoading: false,
      loading: false,
      newRoom: {
        name: "",
        floor_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      floorItems: "floor/getFloors",
      tableOptions: "room/getTableOptions",
    }),
    disableSubmitBtn() {
      if (!this.newRoom.name || !this.newRoom.floor_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchFloors: "floor/fetchAllFloors",
      fetchRooms: "room/fetchRooms",
    }),
    async submitNewRoom() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/room", this.newRoom);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
    fetchData(paginationValue) {
      this.fetchRooms({
        paginationValue,
        nameSearch: this.nameSearch,
        floorId: this.searchFloorId,
      });
    },
  },
  created() {
    if (this.returnAbility("room:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchFloors();
    this.fetchData();
  },
};
</script>

<style>

</style>